import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="markdown-body">
      <header>
        <h1>{`Art Pai's Front-end Developer Resume`}</h1>
        <p><a parentName="p" {...{
            "href": "mailto:minipai@gmail.com"
          }}>{`minipai@gmail.com`}</a></p>
        <p>{`Versatile front-end developer/designer with 9+ years of experience designing and developing SaaS web app. Passionate in design system, crafted in-house UI component library. Always build user interface considering the best user experience and up to date with the latest front-end technologies.`}</p>
      </header>
      <h2>{`Hammers`}</h2>
      <p><inlineCode parentName="p">{`HTML`}</inlineCode>{` `}<inlineCode parentName="p">{`CSS`}</inlineCode>{` `}<inlineCode parentName="p">{`JavaScript`}</inlineCode>{` `}<inlineCode parentName="p">{`TypeScript`}</inlineCode>{` `}<inlineCode parentName="p">{`GraphQL`}</inlineCode>{` `}<inlineCode parentName="p">{`RxJS`}</inlineCode>{` `}<inlineCode parentName="p">{`React`}</inlineCode>{` `}<inlineCode parentName="p">{`Redux`}</inlineCode>{` `}<inlineCode parentName="p">{`Webpack`}</inlineCode>{` `}<inlineCode parentName="p">{`RWD`}</inlineCode>{` `}<inlineCode parentName="p">{`FP`}</inlineCode>{` `}<inlineCode parentName="p">{`Photoshop`}</inlineCode></p>
      <h2>{`Experience`}</h2>
      <section>
        <h3>{`Front-end Developer, `}<a parentName="h3" {...{
            "href": "https://stackla.com"
          }}>{`Stackla`}</a>{`, Sydney`}</h3>
        <p>{`07/2016 — 11/2019 `}<inlineCode parentName="p">{`3Y4M`}</inlineCode></p>
        <ul>
          <li parentName="ul">{`Build UI with Single Page Architecture to provide user quick interaction response`}</li>
          <li parentName="ul">{`Take care of interaction details to provide the best user experience`}</li>
          <li parentName="ul">{`Develop an UI component library`}</li>
          <li parentName="ul">{`Construct a React AJAX architecture`}</li>
          <li parentName="ul">{`Upgrade tech stack to speed up development performance`}</li>
        </ul>
        <p><strong parentName="p">{`TECH STACK`}</strong>{` `}<inlineCode parentName="p">{`TypeScript`}</inlineCode>{` `}<inlineCode parentName="p">{`React`}</inlineCode>{` `}<inlineCode parentName="p">{`Redux`}</inlineCode>{` `}<inlineCode parentName="p">{`RXJS`}</inlineCode>{` `}<inlineCode parentName="p">{`GraphQL`}</inlineCode>{` `}<inlineCode parentName="p">{`Webpack`}</inlineCode>{` `}<inlineCode parentName="p">{`Sass`}</inlineCode></p>
      </section>
      <section>
        <h3>{`Front-end Developer, `}<a parentName="h3" {...{
            "href": "https://bindopos.com/"
          }}>{`Bindo`}</a>{`, Hong Kong`}</h3>
        <p>{`03/2015 – 06/2016 `}<inlineCode parentName="p">{`1Y4M`}</inlineCode></p>
        <ul>
          <li parentName="ul">{`Develop "Bindo Dashboard", a web-based user control panel`}</li>
          <li parentName="ul">{`Build UI with Single Page Architecture provide user fastest UI response`}</li>
          <li parentName="ul">{`Take care of interaction details to provide the best user experience`}</li>
          <li parentName="ul">{`Upgrade tech stack to speed up development performance`}</li>
        </ul>
        <p><strong parentName="p">{`TECH STACK`}</strong>{` `}<inlineCode parentName="p">{`AngularJS`}</inlineCode>{` `}<inlineCode parentName="p">{`Grunt`}</inlineCode>{` `}<inlineCode parentName="p">{`ES2015`}</inlineCode>{` `}<inlineCode parentName="p">{`React`}</inlineCode>{` `}<inlineCode parentName="p">{`Redux`}</inlineCode>{` `}<inlineCode parentName="p">{`Webpack`}</inlineCode>{` `}<inlineCode parentName="p">{`Sass`}</inlineCode>{` `}<inlineCode parentName="p">{`Bootstrap`}</inlineCode></p>
      </section>
      <section>
        <h3>{`Front-end Developer/Designer, Biideal, Taiwan`}</h3>
        <p>{`01/2013 – 03/2015 `}<inlineCode parentName="p">{`2Y3M`}</inlineCode></p>
        <ul>
          <li parentName="ul">{`Design and build landing pages of Biideal website, with RWD`}</li>
          <li parentName="ul">{`Design and build standard showcase pages of our customer (local stores)`}</li>
          <li parentName="ul">{`Design and build back-end admin UI that can do inline-edit and sort with drag-n-drop`}</li>
          <li parentName="ul">{`Deliver new feature and pages of our site at the fastest speed, as a startup`}</li>
        </ul>
        <p><strong parentName="p">{`TECH STACK`}</strong>{` `}<inlineCode parentName="p">{`AngularJS`}</inlineCode>{` `}<inlineCode parentName="p">{`Gulp`}</inlineCode>{` `}<inlineCode parentName="p">{`Bootstrap`}</inlineCode>{` `}<inlineCode parentName="p">{`CoffeeScript`}</inlineCode>{` `}<inlineCode parentName="p">{`Stylus`}</inlineCode>{` `}<inlineCode parentName="p">{`BackboneJS`}</inlineCode>{`, `}<inlineCode parentName="p">{`jQuery`}</inlineCode>{` `}<inlineCode parentName="p">{`Sass`}</inlineCode></p>
      </section>
      <section>
        <h3>{`Front-end Developer/Web Designer, Waveface, Taiwan`}</h3>
        <p>{`11/2011 – 12/2012 `}<inlineCode parentName="p">{`1Y2M`}</inlineCode></p>
        <ul>
          <li parentName="ul">{`Fill every missing design when coding PSD to a real website.`}</li>
          <li parentName="ul">{`Develop the company homepage form PSD provided by the visual designer.`}</li>
          <li parentName="ul">{`Co-working with Django developer to build company’s service (web client).`}</li>
          <li parentName="ul">{`Design UI visual of the web client, everything drawn by CSS3.`}</li>
          <li parentName="ul">{`Develop UI interactions of the web client, powered by JavaScript.`}</li>
          <li parentName="ul">{`Theming thec ompany blog, powered by Wordpress.`}</li>
        </ul>
        <p><strong parentName="p">{`TECH STACK`}</strong>{` `}<inlineCode parentName="p">{`Wordpress`}</inlineCode>{` `}<inlineCode parentName="p">{`Bootstrap`}</inlineCode>{` `}<inlineCode parentName="p">{`BackboneJS`}</inlineCode>{` `}<inlineCode parentName="p">{`jQuery`}</inlineCode>{` `}<inlineCode parentName="p">{`Sass`}</inlineCode></p>
      </section>
      <section>
        <h3>{`Front-end Developer, Freelance, Taiwan`}</h3>
        <p>{`06/2010 – 02/2012 `}<inlineCode parentName="p">{`1Y9M`}</inlineCode></p>
        <ul>
          <li parentName="ul">{`Design template for clients that is a web developer.`}</li>
          <li parentName="ul">{`Code PSD into HTML/CSS for clients that is a designer.`}</li>
          <li parentName="ul">{`Design and develop websites with PHP for ordinary clients.`}</li>
          <li parentName="ul">{`Take photography of client’s products that will be used on websites.`}</li>
        </ul>
        <p><strong parentName="p">{`TECH STACK`}</strong>{` `}<inlineCode parentName="p">{`PHP`}</inlineCode>{` `}<inlineCode parentName="p">{`Codeigniter`}</inlineCode>{` `}<inlineCode parentName="p">{`HTML`}</inlineCode>{` `}<inlineCode parentName="p">{`CSS`}</inlineCode>{` `}<inlineCode parentName="p">{`jQuery`}</inlineCode></p>
      </section>
      <section>
        <h3>{`Front-end Developer/Web Designer, `}<a parentName="h3" {...{
            "href": "http://www.94iplay.tw"
          }}>{`94iPlay`}</a>{`, Taiwan`}</h3>
        <p>{`12/2008 – 10/2010 `}<inlineCode parentName="p">{`1Y11M`}</inlineCode></p>
        <ul>
          <li parentName="ul">{`Delivered over 20 websites to clients.`}</li>
          <li parentName="ul">{`Design Drupal templates and PHP powered websites.`}</li>
          <li parentName="ul">{`Develop Drupal sites and PHP E-commerce websites.`}</li>
          <li parentName="ul">{`Done several print designs, e.g. business card design`}</li>
        </ul>
        <p><strong parentName="p">{`TECH STACK`}</strong>{` `}<inlineCode parentName="p">{`PHP`}</inlineCode>{` `}<inlineCode parentName="p">{`Codeigniter`}</inlineCode>{` `}<inlineCode parentName="p">{`Drupal`}</inlineCode>{` `}<inlineCode parentName="p">{`HTML`}</inlineCode>{` `}<inlineCode parentName="p">{`CSS`}</inlineCode>{` `}<inlineCode parentName="p">{`jQuery`}</inlineCode></p>
      </section>
      <footer>
        <h2>{`Links`}</h2>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://instagram.com/minipai"
            }}>{`Instagram`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.linkedin.com/in/minipai/"
            }}>{`LinkdedIn`}</a></li>
        </ul>
      </footer>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      